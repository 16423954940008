<script>
  import {enquiry, step} from "../stores";
  import axios from 'axios';
  import Step from "../core/Step.svelte";
  import BackButton from "../core/BackButton.svelte";
  import RightChevron from "../icons/RightChevron.svelte";

  function emailPdf() {
    axios.post('/api/generate/pdf/send', {
      enquiry_id: $enquiry.enquiry_id
    })
      .then(() => alert(`Successfully sent email.`))
      .catch(() => alert('Error sending email.'));
  }

  function downloadPdf() {
      // const link = document.createElement('a');
      // link.setAttribute('href', `/api/generate/pdf/download?enquiry_id=${$enquiry.enquiry_id}`);
      // link.setAttribute('download', 'rocol-enquiry-' + $enquiry.enquiry_id + '.pdf');
      // link.setAttribute('target', '_blank');
      // document.body.appendChild(link);
      // link.click(); link.remove();
      window.print();
  }

  function duplicateAndGoToStep(int) {
    axios.post('/api/enquires/duplicate', {
      enquiry_id: $enquiry.enquiry_id
    })
      .then(res => {
        enquiry.set(res.data.data);
        step.update(() => int);
      })
      .catch(err => alert(err.message));
 }
</script>

<Step>
  <div
    class="flex flex-col-reverse md:flex-row md:justify-between items-start
    md:items-center"
    slot="header">
    <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">What next?</h1>
    <BackButton text="Back" on:click={() => step.update(() => 4)} />
  </div>
  <div class="grid p-4 grid-gap-4 grid-columns-12">
    <div
      class="col-span-7 row-span-2 text-white bg-black flex items-center
      justify-center flex-col p-16 border-t-4 border-brand-orange bg-cover
      bg-center"
      style={`background-image: url('img/report-bg.jpg');`}>
      <div class="py-16">
        <h2 class="text-5xl font-bold leading-none text-center">
          Get a personalised cost report delivered directly into your inbox
        </h2>
        <!-- Temporary moved to previous step (window print) -->
        <!-- <div class="mt-6 flex justify-center">
          <button
            on:click={emailPdf}
            class="bg-orange rounded text-white bg-brand-orange py-4 px-6 mr-4"
            >
            Email PDF
          </button>
          <button
            on:click={downloadPdf}
            class="border border-white rounded text-white py-4 px-6"
            Download PDF
            >
          </button>
        </div> -->
      </div>
    </div>

    <button
      class="col-span-5 flex flex-col justify-between border border-gray-200
      hover:bg-gray-100"
      on:click={() => duplicateAndGoToStep(3)}>
      <div class="p-8 w-full">
        <h3 class="text-2xl font-bold leading-tight text-left">
          Select another ROCOL product to compare to your current product.
        </h3>
      </div>
      <div class="px-8 w-full">
        <div
          class="py-4 border-t border-gray-200 flex justify-between items-center
          text-brand-orange leading-none">
          <span>Choose another ROCOL product</span>
          <RightChevron />
        </div>
      </div>
    </button>
    <button
      class="col-span-5 flex flex-col justify-between border border-gray-200
      hover:bg-gray-100"
      on:click={() => duplicateAndGoToStep(2)}>
      <div class="p-8 w-full">
        <h3 class="text-2xl font-bold leading-tight text-left">
          Select a new pack size to compare to your chosen ROCOL products.
        </h3>
      </div>
      <div class="px-8 w-full">
        <div
          class="py-4 border-t border-gray-200 flex justify-between items-center
          text-brand-orange leading-none">
          <span>Select new pack size</span>
          <RightChevron />
        </div>
      </div>
    </button>
  </div>
</Step>
